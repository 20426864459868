@import '../config/_helpers';
.site-preview{
    width: 100%;
    padding: 10px;
    border-radius: 20px;
    display: block;
    position: relative;
    aspect-ratio: 3/3.7;
    overflow: hidden;
    font-size: 14px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    @include at-breakpoint(0,$m){
        aspect-ratio: 4/3;
    }

    .background{
        &:after{
            content: '';        
            position: absolute;
            inset: 0;
            transition: .4s all ease-in-out;
            --first-point: rgba(0,0,0,0.2);
            --second-point: rgba(0,0,0,1);
            background: linear-gradient(to bottom, var(--first-point), var(--second-point));
            display: block;
            opacity: .6;
            @include at-breakpoint(0,$m){
                --first-point: rgba(0,0,0,0.4);
            }
        }
        .backing-image{
            transform-style: preserve-3d;
            transform: perspective(1000px) rotate3D(1, 2, 0, 40deg);
            transition: 1s all ease-in-out;
            width: 150%;
            top: -18%;
            left: -15%;
            position: absolute;
            display: block;
        }
    }
    .company-details{
        position: absolute;
        color: white;
        bottom: 10px;
        padding: 10px;
        .site-name{
            display: block;
        }
        .role{
            font-weight: normal;
            margin-bottom: 5px;
            display: block;
        }
        .pills{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 5px;
            .pill{
                border: 1px solid rgba(255,255,255,0.3);
                padding: 2px 5px;
                border-radius: 10px;
                font-size: smaller;
            }
        }
    }

    &:hover{
        .background{
            .backing-image{
                // transform: translateX(-15%);
                // left: 0;
            }
            &:after{
                // opacity: .8;
            }
        }
    }
}