@import '../config/_helpers';
.skillset{
    background: black;
    color: white;
    hr{
        background: rgba(255,255,255,0.2);
        margin-bottom: 20px;
    }
    ul{
        list-style: none;
        display: flex;
        align-items: center;
        gap: 5px;
        flex-wrap: wrap;
        margin-top: 5px;
        li{
            width: fit-content;
            padding: 1px 10px;
            border-radius: 20px;
            font-size: smaller;
            border: 1px solid rgba(255,255,255,0.2);
        }
    }

    .row > div{
        @include at-breakpoint(0,$m){
            margin-bottom: 10px;
        }
    }
}