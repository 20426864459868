@import "../base.scss";
.home-hero{
    .text-intro{
        text-align: center;
        max-width: 800px;
        margin: 0 auto;
        .subtitle{
            margin: 40px 0;
            font-size: 20px;
            font-family: $heading_font;
            @include at-breakpoint(0,$m){
                margin: 20px 0;
            }
        }
        .quote{
            background: var(--accent-color);
            width: fit-content;
            padding: 10px 40px;
            border-radius: 20px;
            margin: 0 auto;
            margin-bottom: 50px;
            font-family: $heading_font;
        }
        .btn{
            margin: 0 auto;
            width: fit-content;
            display: block;
        }
    }
    

}