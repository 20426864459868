.header{
    position: relative;
    z-index: 2;
    padding: 20px;
    .navigation-links{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
    }
    .logo{
        a{
            width: fit-content;
            max-width: 100px;
            margin: 0 auto;
            display: block;
        }
    }
}