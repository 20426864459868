@import '../base.scss';
.contact-form{
    background: var(--accent-color);
    font-size: 18px;
    text-align: center;
    p{
        max-width: 600px;
        margin: 40px auto;
        @include at-breakpoint(0,$m){
            margin: 20px auto;
        }
    }
    .ctas{
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 16px;
        justify-content: center;
    }
    .title{
        @extend .h1-style;
    }
}