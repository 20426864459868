@mixin at-breakpoint($min,$max:null){     
	@if($max == null and $min != null){ 
		@media (min-width: $min){ 
			@content; 
		} 
	} @elseif($min == null and $max == null) { 
		@content; 
	} @else{ 
		@media (min-width: $min) and (max-width: $max){ 
			@content; 
		} 
	} 
}

@mixin parent($extension: ""){     
    #{$extension} & { 
        @content; 
    } 
}

@mixin aspectRatio($l, $h){
	width: 100%;
	position: relative;
	padding-top: ($h / $l) * 100%;
	img{
		position: absolute;
		top: 0; bottom: 0;
		left: 0; right: 0;
		display: block;
		max-width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

@mixin color_shade_generator ($color, $name, $shades_each_direction: 10, $difference_in_shade: 5){
	@for $i from 1 through $shades_each_direction{ //darker
        --#{$name}-color-dark-#{$i}: #{darken($color, (5*$i))};
    }
    --#{$name}-color: #{$color};
    @for $i from 1 through $shades_each_direction{ //darker
        --#{$name}-color-light-#{$i}: #{lighten($color, (5*$i))};
    }
    --#{$name}-color-bg: linear-gradient(to bottom, #{$color}, #{darken($color, 13%)});
}