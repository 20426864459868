@import '../config/_helpers';
.worked-with{
    .h3-style{
        font-size: 30px;
    }
    p{
        margin: 20px 0;
    }
    .worked-with-carousel-container{
        margin-top: 20px;
        .slider-item, .carousel-slide{
            padding: 20px;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            height: unset !important;
            img{
                width: 100%;
                display: block;
                height: auto;
                max-height: 50px;
                filter: grayscale(1);
                &.invert{
                    filter: invert(1);
                }
            }
        }
    }
}