@import '../config/_helpers';
.project-information{
    padding: 20px;
    background: white;
    border-radius: 20px;
    margin-top: 20px;
    > .row > div{
        position: relative;
        overflow: hidden;
        border: 1px solid rgba(0,0,0,0.1);
        border-radius: 10px;
        max-height: 350px;
        .main-image{
            position: absolute;
            inset: 0;
            top: -15%;
            width: 125%;
            left: -12.5%;
            transform-style: preserve-3d;
            transform: perspective(1200px) rotate3D(.5, -1.5, 0, 15deg);
            @include at-breakpoint(0,$m){
                position: relative;
                max-height: 200px;
                width: 125%;
                top: 0;
                left: -10%;
                object-fit: cover;
            }
        }
    }

    &.project-information-basic{
        > .row > div{
            border: none;
        }
        .row .main-image-container{
            border: 1px solid rgba(0,0,0,0.1);
        }
    }

    

    .project-name{
        font-size: 24px;
        margin-top: 20px;
    }
    .project-quote{
        margin: 20px 0;
    }

    .lifestyle-container{
        @include at-breakpoint(0,$m){
            display: none;
        }
        .project-lifestyles .slider-wrapper{
            max-height: 350px;
             .slider-container .slider-track{
                .slider-item{
                    padding: 0;
                }
            }
        }
    }
    @include at-breakpoint(0,$m){
        // padding: 0;
    }
}