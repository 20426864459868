@import '../config/_helpers';
.carousel-container {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    display: flex;
    align-items: center;
    // gap: 10px;
    transition: scroll-left 0.2s ease-out;

    --slides-per-row: 5.5 !important;

    @include at-breakpoint($m,$xl){
        --slides-per-row: 4 !important;
    }

    @include at-breakpoint(0,$m){
        --slides-per-row: 1.5 !important;
    }
}

.carousel-slide {
    display: block;
    flex: 0 0 calc(100% / var(--slides-per-row));
    width: calc(100% / var(--slides-per-row));
    padding: 10px;
}
