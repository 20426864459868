@import '../config/_helpers';
.slider{
    width: 100%;
    .slider-wrapper{
        position: relative;
        height: 100%;
        .slider-arrow-container{
            position: absolute;
            width: 100%;
            z-index: 2;
            .slider-arrow{
                font-size: 18px;
                cursor: pointer;
                background: white;
                border-radius: 50%;
                aspect-ratio: 1/1;
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 10px;
                box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
            }
        }
        .slider-container{
            flex: 1 1 100%;
            overflow: hidden;
            height: 100%;
            .slider-track{
                position: relative;
                left: 0;
                height: 100%;
                transition: left .4s ease-in-out;
                --gap: 0;
                scroll-snap-type: x mandatory;
                .slider-item{
                    user-select: none;
                    flex: 0 0 calc(100% / var(--slides-per-row));
                    width: calc(100% / var(--slides-per-row));
                    overflow: hidden;
                    padding: 0 5px;
                    height: 100%;
                    scroll-snap-align: start end;
                    .slider-item__img{
                        object-fit: cover;
                        border-radius: 5px;
                    }
                    .slider-item__text{
                        padding: 10px;
                        font-weight: bold; 
                        background: white;
                        border-radius: 0 0 5px 5px;
                        .btn{
                            margin-top: 15px;
                            background: transparent;
                            color: #222;
                            border: 1px solid #222;
                            cursor: pointer;
                            &:hover{
                                color: white;
                                background: #222;
                            }
                        }
                    }
                }
            }
        }
    }
    .slider-indicators-container{
        gap: 5px;
        margin-top: 20px;
        .slider-indicator{
            height: 8px;
            width: 8px;
            border-radius: 50%;
            background: rgba(0,0,0,0.2);
            transition: .4s all ease-in-out;
            &:not(.active):hover{
                transform: scale(1.05);
                cursor: pointer;
                background: rgba(0,0,0,.1);
            }
            &.active{
                background: rgba(0,0,0,.6);
                transform: scale(1.1);
            }
        }
    }

    .slider-wrapper.draggable{
        cursor: grab;
        .slider-container{
            margin: 0 -15px;
            padding: 0 15px;
            .slider-track{
                transition: none;
            }
        }
    }

    &.fade{
        .slider-wrapper{
            .slider-container{
                .slider-track{
                    transition: 0s left;
                    .slider-item{
                        opacity: 0;
                        transition: 0.4s opacity ease-in-out;
                        &.active{
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}