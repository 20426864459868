.footer{
    position: relative;
    z-index: 2;
    padding: 40px;
    .footer-nav{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        font-weight: bold;
    }

    .socials{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        svg{
            font-size: larger;
        }
    }
    .copy{
        opacity: .6;
        margin-top: 40px;
    }

}