@import '../config/_helpers';
.stats-section{
    h3{
        font-size: 30px;
        @include at-breakpoint(0,$m){
            font-size: 24px;
        }
    }
    strong.h3-style{
        font-size: 30px;
        @include at-breakpoint(0,$m){
            font-size: 24px;
        }
    }
    p{
        margin: 20px 0;
    }
    
    .row{
        --gap: 10px;
        .stats-section{
            border-left: 1px solid rgba(0,0,0,0.1);
            @include at-breakpoint(0,$m){
                order: -1;
            }
        }

        .stats{
            height: 100%;
            padding: 10px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 0 30px;
            @include at-breakpoint(0,$m){
                display: none;
            }
            .stat{
                span{
                    font-size: 40px;
                    display: block;
                    font-weight: bold;
                }
            }
        }

        .usps{
            border-top: 1px solid rgba(0,0,0,0.1);
            .usp{
                padding-top: 10px;
                // font-size: smaller;
                p{
                    margin: 0;
                }
            }
        }
    } 
}