*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;

    --scroll-size: 10px;
    --scroll-radius: 20px;
    --scroll-track: rgba(0,0,0,0.1);
    --scroll-thumb-color: rgba(0,0,0,0.3);

    scrollbar-color: var(--scroll-thumb-color) var(--scroll-track);
    scrollbar-width: thin;
    
    &::-webkit-scrollbar {
        width: var(--scroll-size);
        height: var(--scroll-size);
        &-track {
            background-color: var(--scroll-track);
            border-radius: var(--scroll-radius);
        }
        &-thumb {
            background-color: var(--scroll-thumb-color);
            border-radius: var(--scroll-radius);
        }
    }

    ::selection {
        background-color: var(--accent-color-light-1);
        color: black;
    }
}

:root{
    @include color_shade_generator($accent, "accent");
    @include color_shade_generator($cta, "cta");
    scroll-behavior: smooth;
}

html, body{
    font-size: 16px;
    font-family: $body_font;
    background: $body_color;
    scroll-behavior: smooth;
    font-weight: normal;
    line-height: 1.5;
}

.App{
    // display: flex;
    // flex-direction: column;
    // height: 100vh;
    // overflow: hidden;
    // .main-content{
    //     flex: 1;
    //     overflow: scroll;
    //     overflow-x: hidden;
    // }

    > *{
        scroll-behavior: smooth;
    }
}

.container{
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 15px;
    &-small{
        @extend .container;
        max-width: 800px;
        padding: 0;
    }
    @include at-breakpoint(0,$m){
        padding: 0 5px;
    }
}

h1,h2,.heading-font{
    font-family: $heading_font;
}

h1,h2,h3,h4,h5,.h1-style,.h3-style{
    font-weight: normal;
    line-height: 1.1;
}

h1, .h1-style{
    font-size: 62px;
    text-transform: capitalize;
    text-align: center;
    @include at-breakpoint(0,$m){
        font-size: 36px;
    }
}

h2{
    font-size: 18px;
}

h3, .h3-style{
    font-size: 16px;
}

h4{
    font-size: 14px;
}

img{
    width: 100%;
    display: block;
    height: auto;
    object-fit: contain;
}

a{
    text-decoration: none;
    color: inherit;
}

button,.btn{
    color: var(--cta-color);
    padding: 10px 15px;
    display: block;
    text-align: center;
    border: 2px solid var(--cta-color);
    border-radius: 20px;
    margin-top: 10px;
    width: fit-content;
    transition: .4s all ease-in-out;
    background-color: var(--cta-color);
    color: white;
    cursor: pointer;
    font-size: 16px;
    &:hover{
        cursor: pointer;
        background-color: var(--cta-color-light-5);
    }
    svg{
        margin-right: 10px;
    }
    &.emphasis-button { //primary cta
        background: var(--emphasis-color);
        border-color: var(--emphasis-color);
    }
    &.minimal{
        background: none;
        padding: 0;
        border: none;
        color: inherit;
        font-weight: bold;
        &:hover{
            text-decoration: underline;
        }
    }
    &.inverted{
        background: white;
        color: var(--cta-color);
        border-color: white;
        &:hover{
            background: darken(white, 10%);
            border-color: darken(white, 10%);
            color: black;
        }
    }
}

hr{
    border: none;
    height: 1px;
    margin: 10px 0;
    width: 100%;
    background: rgba(0,0,0,0.1);
}

.or{
    margin: 10px 0;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    gap: 20px;
    hr{
        flex: 1;
    }
}

.tagline{
    opacity: .3;
    &:has(svg){
        display: flex;
        align-items: center;
        gap: 5px;
    }
}

.field{
    position: relative;
    margin-bottom: 5px;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid rgba(0,0,0,0.1);
    background: white;
    transition: .4s all ease-in-out;
    label{
        position: absolute;
        font-size: 11px;
        top: 0;
        padding: 5px 10px;
        opacity: .6;
        pointer-events: none;
        user-select: none;
        display: block;
        transition: .1s all ease-in-out;
    }
    input, select, textarea{
        padding: 20px 10px 5px 10px;
        width: 100%;
        border-radius: 0;
        border: none;
        font-size: 15px;
        font-family: $body_font;
        &:invalid + label, &:placeholder-shown + label{
            font-size: 14px;
            top: 50%;
            transform: translateY(-50%);
        }
        &:focus, &:-webkit-autofill{
            outline: none;
            transition: background-color 5000s ease-in-out 0s;
            & + label{
                font-size: 11px;
                top: 0;
                transform: unset;
            }
        }      
    }
    &:has(*:focus){
        border: 1px solid var(--contrast-color);
    }
    &:has(*[required]:invalid){
        &:after{
            content: 'Required';
            position: absolute;
            bottom: 4px;
            right: 4px;
            font-size: 75%;
            opacity: .2;
            pointer-events: none;
            user-select: none;
        }
    }
}

ul,ol{
    list-style-position: inside;
}

.static-page{
    padding-top: 20px;
}

section{
    padding: 40px;
    @include at-breakpoint(0,$m){
        padding: 40px 20px;
    }
}